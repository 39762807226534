<template>
  <div class="main" data-title="销售统计" v-title>
    <!-- <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">销售部统计</h2>
      </div>
    </div> -->
    <el-card class="main-content" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">销售统计<span class="totle">共{{listData.length}}条</span></div>
        <div class="jue-card-btns">
          <select-dep @setDeps="setDeps" ref="selectDep"></select-dep>
          <el-date-picker
            :clearable="false"
            @change="getNewData"
            end-placeholder="结束月份"
            format="yyyy年MM月"
            range-separator="-"
            start-placeholder="开始月份"
            style="margin-left:20px"
            type="monthrange"
            v-model="key"
            value-format="yyyy-MM"
          ></el-date-picker>
        </div>
      </div>

      <div class="no-data" v-if="listData.length == 0">暂无数据</div>
      <div class="table-wrapper" v-else>
        <el-table
          :data="listData"
          :style="'width: '+$store.state.tableWidth"
          border
          class="table"
          show-summary
        >
          <el-table-column label="部门" prop="dept_name" fixed>
            <template slot-scope="scope">{{(scope.row.dept_name).split('/')[0]}}</template>
          </el-table-column>
          <el-table-column label="销售顾问" width="72px" fixed>
            <template slot-scope="scope">{{$store.state.userList[scope.row.seller_uid][0]}}</template>
          </el-table-column>
          <el-table-column label="留存意向客户数" prop="liucunkehu" width="105px"></el-table-column>
          <el-table-column label="继续跟踪客户数" prop="jixugenzong" width="105px"></el-table-column>
          <el-table-column label="当月跟踪客户数" prop="genzongkehu" width="105px"></el-table-column>
          <el-table-column label="当月跟踪次数" prop="genzongcishu" width="105px"></el-table-column>
          
          <el-table-column label="目标订单" prop="mubiaodingdan" width="72px"></el-table-column>
          <el-table-column label="当月订单数" prop="dangyuedingdan" width="90px"></el-table-column>
          <!-- <el-table-column label="当月订单数" width="90px" porp="dangyuedingdan">
            <template slot-scope="scope">{{ parseInt(scope.row.xinzengzhuandingdan) + parseInt(scope.row.liucunzhuandingdan) }}</template>
          </el-table-column> -->
          <el-table-column label="客户接待数" prop="jiedaikehu" width="82px"></el-table-column>
          <el-table-column label="留档客户数" prop="liudangkehu" width="82px"></el-table-column>
          <el-table-column label="来店客户数">
            <el-table-column label="首次来店客户数" prop="shoucilaidian" width="105px"></el-table-column>
            <el-table-column label="邀约到店数" prop="zaicilaidian" width="82px"></el-table-column>
          </el-table-column>
          <el-table-column label="转介绍" prop="zhuanjieshao" width="62px"></el-table-column>
          <el-table-column label="其它业务">
            <el-table-column label="售后业务" prop="shouhouyewu" width="72px"></el-table-column>
            <el-table-column label="其他业务" prop="qitayewu" width="72px"></el-table-column>
          </el-table-column>
          <el-table-column label="当月新增客户" prop="xinzengkehu" width="105px"></el-table-column>
          <el-table-column label="试乘试驾客户数">
            <el-table-column label="首次来店客户试驾数" prop="shoucishijia" width="132px"></el-table-column>
            <el-table-column label="再次来店客户试驾数" prop="zaicishijia" width="132px"></el-table-column>
          </el-table-column>
          <el-table-column label="新增客户转化订单数" prop="xinzengzhuandingdan" width="132px"></el-table-column>
          <el-table-column label="留存客户转化订单数" prop="liucunzhuandingdan" width="132px"></el-table-column>
          <el-table-column label="交车数" prop="jiaoche" width="90px"></el-table-column>
          <el-table-column label="上月遗留订单数" prop="yiliudingdan" width="105px"></el-table-column>
          <el-table-column label="未交订单数" prop="weijiaodingdan" width="90px"></el-table-column>
          <el-table-column label="留档率" prop="tj01" width="90px"></el-table-column>
          <el-table-column label="邀约到店率" prop="tj02" width="90px"></el-table-column>
          <el-table-column label="试乘试驾率" prop="tj03" width="90px"></el-table-column>
          <el-table-column label="本月新增客户订单转化率" prop="tj04" width="105px"></el-table-column>
          <el-table-column label="上月留存客户订单转化率" prop="tj05" width="105px"></el-table-column>
          <el-table-column label="综合订单转化率" prop="tj06" width="90px"></el-table-column>
          <el-table-column label="订单达成率" prop="tj07" width="90px"></el-table-column>
          <el-table-column label="成交率" prop="tj08" width="90px"></el-table-column>
          <el-table-column label="目标达成率" prop="tj09" width="90px"></el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>
<script>
import { monthFormat } from '@/unit/unit.js'
import SelectDep from '@/components/chart/SelectDep.vue'
export default {
  components: {
    SelectDep
  },
  data() {
    return {
      key: [monthFormat(), monthFormat()],
      dept_ids: [],
      last_day: 15,
      listData: []
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    setDeps(arr) {
      this.dept_ids = arr
      this.getListData()
    },
    isCurrFirst(item) {
      //进店时间
      let tmp_t1 = item.income_date.split('-')
      let t1 = tmp_t1[0] + '-' + tmp_t1[1]

      if (t1 >= this.key[0] && t1 <= this.key[1]) {
        return true
      } else {
        return false
      }
    },
    isTrial(item) {
      if (item.income_type == 1 && item.has_trial == 1) {
        return '是'
      } else {
        return '否'
      }
    },
    isTrial2(item) {
      if (item.income_type == 2 && item.has_trial == 1) {
        return '是'
      } else {
        return '否'
      }
    },
    getNewData() {
      this.getListData()
    },
    getListData() {
      this.$axios
        .post('/statistic/data3', {
          begin_month: this.key[0],
          end_month: this.key[1],
          last_day: this.last_day,
          dept_ids: this.dept_ids
        })
        .then(res => {
          if (res.data.code == 200) {
            // this.listData = res.data.data.list
            let listData = res.data.data.list
            let dangyuedingdan = 0
            res.data.data.list.map((item, index) => {
              listData[index].dangyuedingdan = parseInt(item.xinzengzhuandingdan) + parseInt(item.liucunzhuandingdan)

              listData[index].tj01 = this.toPercent(
                item.liudangkehu / item.jiedaikehu
              )
              listData[index].tj02 = this.toPercent(
                item.zaicilaidian / (item.liucunkehu + item.xinzengkehu)
              )
              listData[index].tj03 = this.toPercent(
                (item.shoucishijia + item.zaicishijia) /
                  (item.liucunkehu + item.xinzengkehu)
              )
              listData[index].tj04 = this.toPercent(
                item.xinzengzhuandingdan / item.xinzengkehu
              )
              listData[index].tj05 = this.toPercent(
                item.liucunzhuandingdan / item.liucunkehu
              )
              listData[index].tj06 = this.toPercent(
                (item.xinzengzhuandingdan + item.liucunzhuandingdan) /
                  (item.xinzengkehu + item.liucunkehu)
              )
              listData[index].tj07 = this.toPercent(
                item.xinzengzhuandingdan / item.liucunzhuandingdan
              )
              listData[index].tj08 = this.toPercent(
                item.jiaoche / (item.liucunkehu + item.liudangkehu)
              )
              listData[index].tj09 = this.toPercent(
                item.jiaoche / item.mubiaodingdan
              )
            })
            this.listData = listData
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    toPercent(point) {
      var str = Number(point * 100).toFixed(2)
      str += '%'
      return str
    },
    getCfgData(val) {
      if (val == '') {
        return '-'
      }
      let i = 0

      if (typeof val == 'string') {
        i = parseInt(val)
      } else {
        i = val
      }

      return this.$store.state.cfgData[i][0]
    }
  }
}
</script>
<style lang="less" scoped>
.table-wrapper {
  margin: -20px -20px 0;
  /deep/ .cell {
    text-align: center;
  }

  // /deep/ .el-table__header th,
  // /deep/ .el-table__header tr {
  //   background-color: #17b3a3;

  //   color: black;
  // }
}
</style>